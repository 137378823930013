import React from "react";
import TabLink from "./TabLink";
import data from "../data/variables.json";
import "../css/WhatsApp.css";

export default function WB() {
  const message = `https://api.whatsapp.com/send?phone=${data.contact.phone}&text=${data.contact.reachout}`;
  return (
    <TabLink className="wb t400" to={message} title="Escribinos por WhatsApp">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="288"
        height="288"
        viewBox="0 0 24 24"
      >
        <path
          fill="#fff"
          d="M15.27 13.21c.58.18 1.08.42 1.54.7l-.03-.02c.53.24.99.51 1.4.83l.02.2v.03c-.01.43-.1.83-.27 1.2v-.02c-.22.46-.6.82-1.08 1l-.01.01c-.46.24-1 .4-1.58.41a8.34 8.34 0 0 1-3-.98l.05.02a8.9 8.9 0 0 1-2.64-1.83c-.82-.85-1.58-1.8-2.24-2.8l-.05-.07a5.38 5.38 0 0 1-1.1-3v-.13c.03-.97.47-1.84 1.14-2.44.21-.21.5-.34.81-.35l.3.03H8.5l.3.02a.64.64 0 0 1 .41.1c.1.12.19.26.23.42q.13.31.52 1.37c.13.31.27.7.37 1.1l.02.06c-.08.36-.27.67-.54.89q-.54.57-.54.72a7.03 7.03 0 0 0 1.66 2.35 9.9 9.9 0 0 0 2.29 1.55l.06.02c.1.07.21.1.34.11q.23 0 .84-.75t.8-.75zm-3.15 8.22h.03c1.35 0 2.64-.29 3.81-.8l-.06.02c2.36-1 4.2-2.83 5.16-5.12l.03-.07a9.53 9.53 0 0 0-.02-7.61l.02.06a9.65 9.65 0 0 0-5.13-5.16l-.06-.02a9.53 9.53 0 0 0-7.61.02l.06-.02a9.65 9.65 0 0 0-5.16 5.12l-.03.06a9.6 9.6 0 0 0 1.1 9.5l-.01-.01L3.02 21l3.75-1.19a9.45 9.45 0 0 0 5.3 1.62h.06zm0-21.43h.04c1.62 0 3.17.34 4.57.96l-.07-.03c2.82 1.2 5.03 3.4 6.2 6.15l.02.08a11.5 11.5 0 0 1-.03 9.14l.03-.07a11.62 11.62 0 0 1-6.15 6.2l-.07.02c-1.33.59-2.88.93-4.5.93h-.09c-2.06 0-4-.54-5.66-1.49l.05.03L0 24l2.1-6.28A11.59 11.59 0 0 1 1.4 7.08l-.03.07c1.2-2.82 3.4-5.02 6.15-6.2L7.6.94C8.92.34 10.46 0 12.09 0h.03z"
        />
      </svg>
    </TabLink>
  );
}
